import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import { StyledDocs } from '../assets/styles/templates/docs.styles'
import { Sidebar } from '../components/Sidebar'

const DocsPage = () => {
  return (
    <Layout>
      <StyledDocs>
        <Sidebar />
        <main>
          <header
            style={{
              background: `linear-gradient(to right, #30E8BF, #FF8235)`,
            }}
          >
            <h1>Guides &amp; Documentation</h1>
            <StaticImage
              alt=""
              src="../assets/images/smashcraft-spawn-ss2.png"
            />
          </header>
          <div>beeeep</div>
        </main>
      </StyledDocs>
    </Layout>
  )
}

export default DocsPage
